@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');

@layer base {
    body {
        @apply font-[Quicksand];
    }
    #Navbar {
        @apply font-[Montserrat];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}



*{
  -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  
 ::-webkit-scrollbar-track {
    background: #484848;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #8892B0;
    border-radius: 50vh;
    border: 3px solid #8892B0;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
    border-radius: 50vh;
    border: 3px solid #ffffff;
  }
 

@keyframes backgroundColorPalette {
    0% {
      background: #90F1EF;
    }
    25% {
      background: #FFD6E0;
    }
    50% {
      background: #FFEF9F;
    }
    75% {
      background: #C1FBA4;
    }
    100% {
      background: #7BF1A8;
    }
  }

  @keyframes textColorPalette {
    0% {
      color: #FF70B3;
    }
    25% {
      color: #F8E74F;
    }
    50% {
      color: #6ADC96;
    }
    75% {
      color: #66C5F4;
    }
    100% {
      color: #C77DF2;
    }
  }

  @keyframes hoverColorPalette {
    0% {
      background: #FF70B3;
    }
    25% {
      background: #F8E74F;
    }
    50% {
      background: #6ADC96;
    }
    75% {
      background: #66C5F4;
    }
    100% {
      background: #C77DF2;
    }
  }

  #dark #subtitle {
    color: #8892B0;
  }

  

  #titleName {
    font-family: 'Indie Flower', cursive;
    font-weight: normal;
    font-style: normal;
    animation-name: textColorPalette;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

#light #aboutComponent, #light #skillsComponent, #light #projectsComponent, #light #contactComponent, #light #footer {
    animation-name: hoverColorPalette;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }


  #dark #aboutComponent, #dark #skillsComponent, #dark #projectsComponent, #dark #contactComponent,   #dark #footer {
    background: #121212;
    color: white;
  }

  #skillsCards {
    font-family: Figtree;
    font-weight: 700;
    font-style: normal;
  }



  #projectsBtn {
         /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, #181818 50%, #36d2f5 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
  }

  #projectsBtn:hover {
    background-position: left;
}


  #dark #skillsCard {
    color: #36d2f5;
    border: 1px solid #36d2f5;
    animation-name: none;
  }
  #dark #skillsCard:hover {
    background-size: 200%;
    background: #36d2f5;
    color: #181818;
    transition: .5s ease-out;
  }

  #light #nav-item:hover {
    /*background: -webkit-linear-gradient(0deg,#5393f9, #f953f3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
    animation-name: textColorPalette;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: all 0.2s ease-in-out;
    border-bottom-style: solid;
    border-color: linear-gradient(0deg,#5393f9, #f953f3);;
  }

  #dark #nav-item:hover {
    /*background: -webkit-linear-gradient(0deg,#5393f9, #f953f3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
    color: #36d2f5;
    transition: all 0.2s ease-in-out;
    border-bottom-style: solid;
    border-color: linear-gradient(0deg,#5393f9, #f953f3);;
  }

  #dark #projectCard {
    --tw-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.8), 0 2px 4px -2px rgba(255, 255, 255, 0.8);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(255, 255, 255, 0.8)), var(--tw-ring-shadow, 0 0 rgba(255, 255, 255, 0.8)), var(--tw-shadow);

  }

  #dark #title{
    border-bottom: 4px solid #36d2f5;
    color: #CAD3F3;
  }

  .switch {
    position: fixed;
    background: #181818;
  }
  
  
  #light .switch label,  #dark .switch label  {
    color: white;
  }

  #dark #contactComponent textarea, #dark #contactComponent input {
    background: #707070;
    color: white;
  }

   #footerContent {

    @media (max-width: 768px) {
      display: block;
      width: 100%;
      max-width: 270px;
      margin: 0 auto 10px;
    }
  }
  
.loaderContainer {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.fadeIn {
  animation: fadeInAnimation 1s linear;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOutAnimation 1s linear;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#root {
  background-color: #121212;
}

